@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.address-input-panel {
  padding: 12px;
  border: 1px solid var(--form-control-border);
  border-radius: 8px;
  background-color: var(--form-control-background);
  transition: all .2s ease-out;

  .label-row {
    margin-bottom: 6px;

    .address {
      cursor: pointer;
      font-size: 12px;
      font-weight: normal;
    }
  }

  .input-row {

    .recipient-address-input {
      width: 100%;
      padding: 4px 0;
      color: var(--text-primary);
      position: relative;
      font-weight: 400;
      outline: none;
      border: none;
      background-color: transparent;
      font-size: 14px;
      text-align: left;
    }
  }

  &:focus-within {
    border: 1px solid var(--form-control-outline);
  }

  &.is-error {
    border: 1px solid var(--error);
  }
}
