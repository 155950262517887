.wallet-circle-icon {
  border-radius: 50%;
  overflow: hidden;
  display: flex;

  img {
    width: 100%;
    height: 100%;
  }
}
