.chain-selector {
  width: 135px;
  margin-right: 8px;

  .select-wrap {
    cursor: pointer;
    background-color: var(--btn-icon-rect-background-light);
    border-color: var(--btn-icon-rect-background-light);
    &:focus-within {
      border-color: var(--btn-icon-rect-background-light);
    }

    &:hover {
      background-color: var(--btn-secondary-hover);
    }
    .value-block {
      height: 32px;
    }
  }

  .form-control-select-option {
    padding: 6px 4px;
  }
}
