@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.dark-mode .modal.search-currency-modal {
  --btn-basses-bg: #3a3a3c;
  --btn-basses-hover: #67676a;
}

.modal.search-currency-modal {
  --btn-basses-bg: white;
  --btn-basses-hover: #353a4a;
  padding: $window-padding 0 0;

  .modal-header {
    padding: 0 $window-padding;

    .title {
      font-weight: 500;
    }
  }

  .modal-content.currency-search-content {
    overflow: hidden;
    padding: 0;

    .search-currency-input-wrap {
      padding: 0 $window-padding;
      .search-currency-input {
        width: 100%;
        background-color: var(--bg-secondary);
        padding: 15px 14px;
        border: 1px solid var(--border-primary);
        border-radius: 8px;
        outline: none;
        transition: border-color 0.2s ease-out;

        &:focus {
          border-color: #a32ccd;
        }
      }
    }

    .common-basses {
      margin-top: 20px;
      color: var(--text-secondary);

      .label {
        margin-bottom: 16px;
      }

      .basses-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .btn-basses-item {
          height: auto;
          padding: 6px 12px 6px 8px;
          background-color: var(--btn-basses-bg);
          border: 1px solid var(--border-primary);
          border-radius: 6px;
          display: flex;
          align-items: center;

          .label {
            top: 1px;
            margin: 0 0 0 8px;
            font-size: 16px;
            font-weight: 500;
            position: relative;
          }

          &:hover {
            background-color: var(--btn-basses-hover);
            border-color: var(--btn-basses-hover);
            color: var(--text-primary-for-dark-bg);
          }

          &:disabled {
            background-color: var(--btn-basses-bg);
            border: 1px solid var(--border-primary);
            color: inherit;
            cursor: default;
            opacity: 0.6;
          }
        }
      }
    }
    .search-currency-scroll-block {
      margin-bottom: auto;
      overflow: auto;
      padding: 0 $window-padding;
    }

    .search-currency-sorter {
      margin: 16px 0 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        font-size: 14px;
        color: var(--text-secondary);
      }
    }

    .search-currency-list {
      margin: 0 -24px auto;
      cursor: pointer;
      border-top: 1px solid var(--border-secondary);
      border-bottom: 1px solid var(--border-secondary);
      background-color: var(--bg-secondary);

      .currency-list-view {
        .search-currency-item {
          height: 50px;
          padding: 0 $window-padding;
          background-color: var(--bg-secondary);
          transition: background-color 0.2s ease-out;
          display: flex;
          align-items: center;
          position: relative;
          overflow: hidden;

          .currency-logo {
            width: 32px;
            min-width: 32px;
            height: 32px;
            margin-right: 16px;
            padding: 4px;
            border: 1px solid #dbdde1;
            border-radius: 50%;

            .currency-logo-wrapper {
              display: block;
            }
          }

          .currency-name {
            margin-right: auto;
            display: flex;
            flex-direction: column;

            .symbol {
              font-size: 16px;
            }

            .info {
              font-size: 10px;
              color: rgba(var(--text-secondary), 0.7);

              .btn {
                margin-left: 4px;
                font-size: 10px;
              }
            }
          }

          .currency-tag-container {
            display: flex;
            justify-content: flex-end;

            .tag {
              margin-right: 4px;
              background-color: var(--btn-primary);
              color: white;
              font-size: 10px;
              border-radius: 4px;
              padding: 0.25rem 0.3rem;
              max-width: 6rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              justify-self: flex-end;
            }
          }

          .currency-balance {
            width: 90px;
            min-width: 90px;
            text-align: right;

            .balance {
              font-size: 13px;
            }
          }

          .actions {
            right: 0;
            top: 0;
            bottom: 0;
            padding: 12px 20px 12px 12px;
            background-color: var(--bg-secondary);
            box-shadow: -14px 0 15px var(--bg-secondary);
            opacity: 0;
            transition: all 0.2s ease-out;
            position: absolute;
            z-index: 2;
          }

          &.disabled {
            cursor: default;
            pointer-events: none;
            opacity: 0.5;
          }

          &.selected {
            background-color: var(--btn-thirdly);
          }

          &:hover:not(.disabled):not(.selected) {
            background-color: var(--bg-primary);

            .actions {
              background-color: var(--bg-primary);
              box-shadow: -14px 0 15px var(--bg-primary);
              opacity: 1;
            }
          }
        }

        .empty-currency-list {
          display: flex;
          flex-direction: column;
          align-items: center;

          .image {
            svg {
              height: 50px;
              width: auto;
            }
          }

          .message {
            padding: 16px 50px;
            text-align: center;
          }
        }
      }
    }

    .search-currency-selected-list {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .list-name {
        display: flex;
        align-items: center;

        .logo {
          width: 32px;
          height: 32px;
          min-width: 32px;
          margin-right: 16px;
          padding: 3px;
          border: 1px solid #dbdde1;
          border-radius: 50%;
        }

        .name {
          color: var(--text-primary);
          font-size: 14px;
        }
      }

      .btn {
        margin-left: auto;
        font-size: 14px;
        background: var(--btn-thirdly);
        border-radius: 6px;

        &:hover {
          color: white;
          background: var(--btn-thirdly-hover);
        }
      }
    }
  }
}
