@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.dark-mode .bridge-page {
  --btn-switch-bg: #2C2C2E;
  --btn-switch-active: #242426;
  --chain-selector-bg: #515153;
}

.bridge-page {
  --btn-switch-bg: #EDEDF2;
  --btn-switch-active: white;
  --chain-selector-bg: #f5f5f9;

  width: 432px;
  max-width: 100%;
  margin: 0 auto;

  .bridge-source-selector {
    width: 100%;
    margin-bottom: 16px;
    padding: 2px;
    background: var(--bg-fourthly);
    border-radius: 6px;
    display: flex;

    .btn-tab {
      width: 50%;
      height: 36px;
      padding: 8px 10px;
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: var(--text-primary);
      text-decoration: none;
      line-height: 20px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      border-radius: 4px;
      background-color: transparent;
      transition: background-color .2s ease-in-out;

      &.active {
        font-weight: 500;
        background-color: var(--bg-secondary);;
      }
    }
  }

  .bridge-card {
    width: 100%;
    background: var(--bg-secondary);
    border-radius: 16px;

    .bridge-card-content {

      .test-network-warning {
        margin-bottom: 20px;
        padding: 6px 10px;
        color: var(--error);
        border: 1px solid var(--error);
        border-radius: 8px;

        .pi {
          top: 1px;
          margin-right: 6px;
          position: relative;
        }
      }

      .chain-id-selector {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selector-label {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }

        .selector {
          width: 190px;

          .select-wrap {
            background-color: var(--chain-selector-bg);
            border-color: var(--chain-selector-bg);

            .value-block {
              height: 32px;
            }

            .form-control-select-option {
              padding: 6px 4px;
            }

            &:hover {
              background-color: var(--btn-secondary-hover);
            }

            &:focus-within {
              border-color: var(--form-control-outline);
            }
          }
        }
      }

      .form-control {
        margin-bottom: 4px;

        .error {
          min-height: 14px;
          margin-top: 4px;
          padding-left: 6px;
          font-size: 12px;
          color: var(--error);
        }
      }

      .swap-fields {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > i {
          background: var(--bg-primary);
          display: inline-block;
          width: 32px;
          height: 32px;
          border-radius: 4px;
          line-height: 32px;
          text-align: center;
        }
      }

      .recipient-form-control {

        .label {
          margin-bottom: 10px;
          display: block;
        }

        .error {
          min-height: 14px;
          margin-top: 4px;
          padding-left: 6px;
          font-size: 12px;
          color: var(--error);
        }
      }

      .bridge-info {
        margin-bottom: 16px;
        background: var(--bg-thirdly);
        border-radius: 8px;
        padding: 12px;

        .row-between {

          .label {
            font-size: 12px;
            line-height: 16px;
            font-weight: 500;
            text-transform: capitalize;

            img {
              width: 17px;
              height: 17px;
              margin: 0 5px -4px 0;
            }
          }

          .buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn {
              padding: 3px 5px 1px;
              height: 16px;
              font-size: 10px;
              line-height: 12px;
              width: auto;
              margin-left: 4px;
              font-weight: 500;
              text-decoration: none;
              border-radius: 3px;
            }
          }

          &:last-child:not(:first-child) {
            margin-top: 10px;
          }
        }
      }

      .dropdown {
        padding: 16px 16px 0;

        .row-between {
          padding: 10px 0;
          color: var(--text-secondary);
          font-size: 14px;
          border-bottom: 1px solid var(--row-separator);

          .value {
            color: var(--text-primary);
            font-weight: 500;

            .logo {
              top: 2px;
              margin-right: 6px;
              position: relative;
              display: inline-block;
            }
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }

      .button-block {
        margin-top: 16px;

        .btn {
          width: 100%;
        }
      }
    }
  }
}
