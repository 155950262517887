@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.form-control-select {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px;
    font-size: 14px;
    color: var(--text-secondary);
  }

  .select-wrap {
    background-color: var(--form-control-background);
    border: 1px solid var(--form-control-border);
    border-radius: 8px;
    transition: background-color .2s ease-out, border-color .2s ease-out;
    position: relative;
    cursor: pointer;

    select {
      top: 5px;
      left: 5px;
      opacity: 0;
      position: absolute;
      z-index: 0;
    }

    .value-block {
      width: 100%;
      height: 46px;
      padding: 0 14px 0 0;
      background-color: inherit;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;

      .drop-down {
        transition: all .2s ease-in-out;
      }

      .empty {
        padding: 0 16px;
        color: var(--text-thirdly);
      }
    }

    .options-block {
      left: -1px;
      right: -1px;
      top: calc(100% + 1px);
      max-height: 200px;
      border-radius: 0 0 8px 8px;
      border: 1px solid var(--border-primary);
      border-top: none;
      background-color: var(--form-control-background);
      overflow-y: auto;
      position: absolute;
      box-shadow: var(--shadow-dropdown);
      z-index: $z-index-settings-popup;

      & > *:hover {
        background-color: var(--form-control-selector-hover);
        cursor: pointer;
      }

      & > *:last-child {
        border-radius: 0 0 8px 8px;
      }
    }

    &:focus-within {
      border-color: var(--form-control-outline);
    }
  }

  .error-massage {
    margin: 3px 0 0 8px;
    font-size: 10px;
    color: $error;
  }

  &.is-error {

    .select-wrap {
      border-color: var(--error);
    }
  }

  &.is-open {

    .select-wrap {
      border-radius: 8px 8px 0 0;

      .value-block {

        .drop-down {
          transform: scaleY(-1);
        }
      }
    }
  }

  &.is-disabled {

    .select-wrap {
      background-color: var(--form-control-disabled-background);
      border-color: var(--form-control-disabled-border);
      cursor: default;
    }
  }
}

.form-control-select-option {
  padding: 6px 16px;
  display: flex;
  align-items: center;
  user-select: none;

  .logo {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .label {
    font-weight: 500;
  }
}
