@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.modal.modal-bridge-asset-selector {
  padding: 0;
  width: 424px;

  .modal-content {
    height: 660px;
    max-height: 660px;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .networks-selector {
      padding: 14px 0;
      border-right: 1px solid var(--border-thirdly);
      width: 56px;

      .btn-network {
        width: 40px;
        height: 40px;
        padding: 0;
        margin: 8px;
        background: transparent;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .5;

        .logo {
          width: 24px;
          height: 24px;
        }

        &:hover,
        &.active {
          opacity: 1;
          background: var(--form-control-border);
        }
      }
    }

    .assets-container {
      width: calc(100% - 56px);
      display: flex;
      flex-direction: column;

      .header {
        width: 100%;
        padding: 14px 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        border-bottom: 1px solid var(--border-thirdly);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .search {
        height: 46px;
        width: 100%;
        padding: 10px 20px;
        border-bottom: .75px solid var(--border-thirdly);
        outline: none;
        display: flex;
        align-items: center;

        .search-icon {
          width: 14px;
          height: 14px;
          margin-right: 10px;
          color: var(--text-secondary);
        }

        .input {
          width: calc(100% - 24px);
          border: none;
          outline: none;
          background: transparent;

          &:focus {
            outline: none;
          }
        }
      }

      .assets {
        overflow-y: auto;
        flex-grow: 1;
        position: relative;

        .btn-asset {
          width: 100%;
          height: 56px;
          padding: 12px 16px;
          background: transparent;
          border-radius: 0;
          display: flex;
          align-items: center;

          .info {
            width: 100%;
            text-align: left;
            padding-left: 12px;

            .symbol {
              font-weight: 500;
            }

            .name {
              font-size: 12px;
              color: var(--text-secondary);
            }
          }

          .balance {
            font-weight: 500;
            font-size: 14px;
          }

          &:hover,
          &.active {
            background: var(--form-control-selector-active);
          }
        }

        .loading-asset {
          width: 100%;
          height: 56px;
          padding: 12px 16px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
