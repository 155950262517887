.assets-block {
  .assets-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
    line-height: 20px;
  }
  .assets-header {
    margin: 8px 0 16px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 500;
    color: var(--text-secondary);
    text-align: right;
    .asset {
      width: 30%;
      text-align: left;
    }
    .balance {
      width: 35%;
    }
    .value {
      width: 35%;
    }
  }
}
