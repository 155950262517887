@mixin xs {
  @media only screen and (max-width: 500px) {
    @content;
  }
}

@mixin sm {
  @media only screen and (max-width: 720px) {
    @content;
  }
}

@mixin md {
  @media only screen and (max-width: 960px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin media-breakpoint($min, $max) {
  @if $min and $max {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
