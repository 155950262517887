.dark-mode .img-not-found {
  --circle-color: #515153;
  --loupe-color: #67676A;
  --magnifying-glass-color: rgba(255, 255, 255, .2);
}

.img-not-found {
  --circle-color: #E3E5EB;
  --loupe-color: #C2C5CE;
  --magnifying-glass-color: rgba(255, 255, 255, .5);

  .filled-circle {
    fill: var(--circle-color);
  }

  .dashed-circle {
    stroke: var(--circle-color);
    fill: transparent;
  }

  .loupe {
    stroke: var(--loupe-color);
    fill: var(--magnifying-glass-color);
  }

  .loupe-handle {
    stroke: var(--loupe-color);
  }
}
