html,
body,
input,
textarea,
button {
  font-size: 14px;
  font-variant: none;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: var(--text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
}

a {
  color: var(--text-link);
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

h1 {
  font-size: 36px;
  line-height: 40px;
}

h2 {
  font-size: 26px;
  line-height: 28px;
}

h3 {
  font-size: 24px;
  line-height: 26px;
}

h4 {
  font-size: 20px;
  line-height: 22px;
}

h5 {
  font-size: 18px;
  line-height: 20px;
}

h6 {
  font-size: 16px;
  line-height: 18px;
}
