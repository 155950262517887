.wallet-info {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background: var(--bg-secondary);
  z-index: 5;
  height: 24px;
  .address {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-secondary);
    margin: 0 16px;
  }
  .button-container {
    display: flex;
    align-items: center;

    button {
      height: 16px;
      padding: 3px;
      background-color: var(--bg-primary);
      & + button {
        margin-left: 4px;
      }
      a {
        text-decoration: none;
      }
      .tooltiped-element {
        display: block;

        i {
          color: var(--text-secondary);
          font-size: 10px;
          display: block;
        }
      }
    }
  }

  .total {
    font-size: 18px;
    font-weight: 500;
    margin-left: 8px;
  }
}
