// Roboto
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/../fonts/roboto/Roboto-Bold.eot');
  src: url('../fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Bold.woff') format('woff'), url('../fonts/roboto/Roboto-Bold.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-BlackItalic.eot');
  src: url('../fonts/roboto/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-BlackItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-BlackItalic.woff') format('woff'), url('../fonts/roboto/Roboto-BlackItalic.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-BlackItalic.svg#Roboto-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Italic.eot');
  src: url('../fonts/roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Italic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Italic.woff') format('woff'), url('../fonts/roboto/Roboto-Italic.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Light.eot');
  src: url('../fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Light.woff') format('woff'), url('../fonts/roboto/Roboto-Light.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Black.eot');
  src: url('../fonts/roboto/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Black.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Black.woff') format('woff'), url('../fonts/roboto/Roboto-Black.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Black.svg#Roboto-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-BoldItalic.eot');
  src: url('../fonts/roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-BoldItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-BoldItalic.woff') format('woff'), url('../fonts/roboto/Roboto-BoldItalic.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Regular.eot');
  src: url('../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Regular.woff') format('woff'), url('../fonts/roboto/Roboto-Regular.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-ThinItalic.eot');
  src: url('../fonts/roboto/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-ThinItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-ThinItalic.woff') format('woff'), url('../fonts/roboto/Roboto-ThinItalic.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-ThinItalic.svg#Roboto-ThinItalic') format('svg');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Thin.eot');
  src: url('../fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Thin.woff') format('woff'), url('../fonts/roboto/Roboto-Thin.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-LightItalic.eot');
  src: url('../fonts/roboto/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-LightItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-LightItalic.woff') format('woff'), url('../fonts/roboto/Roboto-LightItalic.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-LightItalic.svg#Roboto-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-MediumItalic.eot');
  src: url('../fonts/roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-MediumItalic.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-MediumItalic.woff') format('woff'), url('../fonts/roboto/Roboto-MediumItalic.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/roboto/Roboto-Medium.eot');
  src: url('../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/roboto/Roboto-Medium.woff') format('woff'), url('../fonts/roboto/Roboto-Medium.ttf') format('truetype'),
    url('../fonts/roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
