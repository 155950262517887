@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.btn-approve {

  .error-text {
    font-size: 14px;
  }

  &.btn-success {

    .pi {
      top: 5px;
      margin-top: -10px;
      margin-right: 8px;
      font-size: 22px;
      position: relative;
    }
  }
}
