.wallet {
  white-space: nowrap;
  border-radius: 4px;
  background-color: var(--btn-icon-rect-background-light);
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  margin: 0 8px 0 auto;
  &.connected {
    padding: 0 2px 0 6px;
    cursor: default;
  }
  .info {
    display: flex;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
  }
  .balance {
    line-height: 12px;
    text-align: center;
    min-width: 30px;
  }
}
