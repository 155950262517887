@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.dark-mode .currency-input-panel {
  --btn-currency-bg: #515153;
  --btn-currency-hover: #67676a;
}

.currency-input-panel {
  --btn-currency-bg: #f5f5f9;
  --btn-currency-hover: #353a4a;

  padding: 12px;
  background-color: var(--form-control-background);
  border: 1px solid var(--form-control-border);
  border-radius: 8px;
  transition: all 0.2s ease-out;
  overflow: hidden;
  position: relative;

  .loading {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;

    .backdrop {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--form-control-background);
      opacity: 0.7;
      position: absolute;
      z-index: 1;
    }

    .pi-loader {
      font-size: 22px;
      position: relative;
      z-index: 2;
    }
  }

  .label-row {
    min-height: 16px;
    color: var(--text-secondary);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      margin-right: auto;
    }

    .balance {
      cursor: pointer;

      &.max-disabled {
        cursor: default;
      }
    }
  }

  .input-row {
    margin-top: 8px;
    display: flex;
    align-items: center;

    .token-amount-input {
      margin-right: auto;
      font-size: 20px;
    }

    .btn-not-selected {
      height: 32px;
      margin-left: 8px;
      white-space: nowrap;

      .drop-down {
        top: -2px;
        margin-left: 6px;
        display: inline-block;
        position: relative;

        &:before,
        &:after {
          background-color: var(--text-primary-for-dark-bg);
        }
      }
    }

    .btn-currency-selector {
      height: 32px;
      margin-left: 8px;
      padding: 4px 14px 4px 8px;
      background-color: var(--btn-currency-bg);
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: default;
      transition: background-color 0.2s ease-out;

      .token-logo {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border-radius: 50%;
        overflow: hidden;
      }

      .pair-logo {
        & > * {
          display: flex;
        }
      }

      .token-name {
        margin-left: 8px;
        font-size: 20px;
        font-weight: 500;
      }

      .drop-down {
        margin-left: 10px;
      }

      &:hover:not(.disabled) {
        background-color: var(--btn-currency-hover);
        color: var(--text-primary-for-dark-bg);
        cursor: pointer;

        .drop-down:after {
          background-color: var(--text-primary-for-dark-bg);
        }

        .drop-down:before {
          background-color: var(--text-primary-for-dark-bg);
        }
      }
    }
  }

  &:focus-within {
    border: 1px solid var(--form-control-outline);
  }

  &.is-error {
    border: 1px solid var(--error);
  }
}
