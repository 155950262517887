// Theme colors
body {
  /* Social Colors */
  --social-twitter: #4a99e9;
  --social-redit: #ec612d;
  --social-facebook: #4661a4;
  --social-email: #3581ce;
  --social-telegram: #2ea6d9;
  --social-wechat: #5eb836;
  --social-discord: #5862ed;
  --social-whatsapp: #6ac060;
  --social-youtube: red;
  /* APP COLORS */
  --success: #1fc781;
  --error: #fe453d;
  --warning: #f3841e;

  /* BACKGROUNDS */
  --bg-primary: #f5f5f9; // Body background
  --bg-secondary: white; // Cards
  --bg-thirdly: #f5f5f9; // Blocks inside the cards or separators
  --bg-fourthly: #e3e5eb; // Some info blocks background, progress bar, etc
  --bg-dropdown: white; // Header settings popup, gas settings popup etc.

  /* BORDERS COLOR */
  --border-primary: #e3e5eb;
  --border-secondary: #eaecf2;
  --border-thirdly: #f5f5f9; // Used as row separator (very light)

  /* SHADOWS */
  --shadow-primary: 0 13.4px 26.8px rgba(0, 0, 0, .04); // Used for card shadow or similar
  --shadow-dropdown: 0 12px 24px rgba(136, 148, 185, .16); // Dropdowns popovers

  /* TEXT COLORS */
  --text-primary: black;
  --text-secondary: #565a69;
  --text-thirdly: #b0b0b8;
  --text-primary-for-dark-bg: white;
  --text-secondary-for-dark-bg: #bec6cb;
  --text-thirdly-for-dark-bg: #9b9ba7;
  --text-link: #007aff;

  /* BUTTON COLORS */
  --btn-primary: #191e2e;
  --btn-primary-hover: #353a4a;
  --btn-primary-disabled: #b2b4bb;
  --btn-secondary: #e3e5eb;
  --btn-secondary-hover: #d3d5da;
  --btn-secondary-disabled: rgba(227, 229, 235, .7);
  --btn-thirdly: #e3e5eb;
  --btn-thirdly-hover: #d3d5da;
  --btn-thirdly-disabled: rgba(227, 229, 235, .7);
  --btn-danger: #fe453d;
  --btn-danger-hover: #fe362e;
  --btn-danger-disabled: rgba(254, 54, 46, .7);
  --btn-success: rgba(52, 205, 145, .1);
  --btn-success-color: #34cd91;
  --btn-success-hover: rgba(48, 194, 137, .15);
  --btn-success-disabled: rgba(227, 229, 235, .7);
  --btn-hamburger: black;
  --btn-icon-hover: rgba(211, 213, 218, .4);
  --btn-icon-rect: white;
  --btn-icon-rect-background-light: #f5f5f9; // Used for white foreground
  --btn-icon-rect-hover: #353a4a;
  --btn-icon-rect-active: #191e2e;
  --btn-max: #d3d6e2;
  --btn-max-hover: #353a4a;

  /* TOOLTIP */
  --tooltip-bg: rgba(41, 42, 76, .9);
  --tooltip-text: white;

  /* LEFT MENU */
  --left-menu-bg: #23232d;
  --left-menu-link-active: #333340;
  --left-menu-link-hover: #2b2b37;
  --left-menu-separator: #333340;

  /* LABELS */
  --label-primary: white; // Used with --bg-primary foreground
  --label-secondary: #f5f5f9; // Used with --bg-secondary foreground

  /* FORM CONTROLS */
  --form-control-border: #dbdde1;
  --form-control-outline: #191e2e;
  --form-control-background: white;
  --form-control-pleaceholder: #b2b4bb;
  --form-control-disabled-border: #f5f5f9;
  --form-control-disabled-background: #f5f5f9;
  --form-control-selector-active: #f5f5f9; // Used in selector dropdown or some list selector
  --form-control-selector-hover: #f5f5f9;
  --form-control-radio: #191e2e;

  /* SCROLL BAR */
  --scroll-bar: #b2b4bb;

  /* D3 CHARTS */
  --chart-greed: #bec6cb;
  --chart-axis-line: #aeb5ba;
  --chart-crosshair: #191e2e;
  --chart-scale-active: #191e2e;
  --chart-donut-shadow: #191e2e;

  &.dark-mode {
    /* APP COLORS */
    --success: #1fc781;
    --error: #fe453d;

    /* BACKGROUNDS */
    --bg-primary: #1c1c1e;
    --bg-secondary: #242426;
    --bg-thirdly: #2c2c2e;
    --bg-fourthly: #1c1c1e;
    --bg-dropdown: #2c2c2e;

    /* BORDERS COLOR */
    --border-primary: #3a3a3c;
    --border-secondary: #242426;
    --border-thirdly: #3a3a3c; // Used as row separator (very light)

    /* SHADOWS */
    --shadow-primary: 0 13.4px 26.8px rgba(0, 0, 0, .04);
    --shadow-dropdown: 0 12px 24px rgba(27, 27, 31, .5);

    /* TEXT COLORS */
    --text-primary: #ececf5;
    --text-secondary: #c5c5cc;
    --text-thirdly: #9e9ea3;
    --text-primary-for-dark-bg: white;
    --text-secondary-for-dark-bg: #bec6cb;
    --text-thirdly-for-dark-bg: #9b9ba7;
    --text-link: #007aff;

    /* BUTTON COLORS */
    --btn-primary: #007aff;
    --btn-primary-hover: #0a84ff;
    --btn-primary-disabled: #66676c;
    --btn-secondary: #515153;
    --btn-secondary-hover: #67676a;
    --btn-secondary-disabled: rgba(81, 81, 83, .7);
    --btn-thirdly: #007aff;
    --btn-thirdly-hover: #0a84ff;
    --btn-thirdly-disabled: #66676c;
    --btn-danger: #fe453d;
    --btn-danger-hover: #fe362e;
    --btn-danger-disabled: rgba(254, 54, 46, .7);
    --btn-success: rgba(52, 205, 145, .1);
    --btn-success-color: #34cd91;
    --btn-success-hover: rgba(48, 194, 137, .15);
    --btn-success-disabled: rgba(81, 81, 83, .7);
    --btn-hamburger: white;
    --btn-icon-hover: rgba(103, 103, 106, .2);
    --btn-icon-rect: #3a3a3c;
    --btn-icon-rect-background-light: #3a3a3c; // Used for white foreground
    --btn-icon-rect-hover: #67676a;
    --btn-icon-rect-active: #515153;
    --btn-max: #515153;
    --btn-max-hover: #67676a;

    /* TOOLTIP */
    --tooltip-bg: rgba(81, 81, 83, .9);
    --tooltip-text: white;

    /* LEFT MENU */
    --left-menu-bg: #2c2c2e;
    --left-menu-link-active: #3a3a40;
    --left-menu-link-hover: #414148;
    --left-menu-separator: #3a3a3c;

    /* LABELS */
    --label-primary: #3a3a3c;
    --label-secondary: #3a3a3c;

    /* FORM CONTROLS */
    --form-control-border: #3a3a3c;
    --form-control-outline: #007aff;
    --form-control-background: #3a3a3c;
    --form-control-pleaceholder: #9e9ea3;
    --form-control-disabled-border: #1c1c1e;
    --form-control-disabled-background: #1c1c1e;
    --form-control-selector-active: #3a3a3c;
    --form-control-selector-hover: #515153;
    --form-control-radio: #515153;

    /* SCROLL BAR */
    --scroll-bar: rgba(255, 255, 255, .15);

    /* D3 CHARTS */
    --chart-greed: #4c4d52;
    --chart-axis-line: #8e9297;
    --chart-crosshair: #007aff;
    --chart-scale-active: #007aff;
    --chart-donut-shadow: #8894b9;
  }
}
