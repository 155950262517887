.wallet {
  .info {
    .address {
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      border-radius: 2px;
      margin-left: 6px;
      padding: 0 6px;
      display: flex;
      align-items: center;
      height: 28px;
      background-color: var(--bg-secondary);
      .wallet-address-menu {
        background-color: black;
        position: absolute;
        top: 32px;
        right: 0;
        z-index: 6;
        background: var(--left-menu-bg);
        border-radius: 8px;
        box-shadow: var(--shadow-dropdown);
        color: var(--text-secondary-for-dark-bg);
        a {
          text-decoration: none;
        }

        .menu-item {
          align-items: center;
          background-color: transparent;
          border: none;
          border-radius: 4px;
          color: var(--text-secondary-for-dark-bg);
          cursor: pointer;
          display: flex;
          outline: none;
          overflow: hidden;
          padding: 10px 24px;
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          .pi {
            font-size: 12px;
            color: var(--text-secondary-for-dark-bg);
            margin-right: 12px;
          }
          &.disconnect {
            border-top: 1px solid var(--left-menu-separator);
            color: var(--error);

            i {
              color: var(--error);
            }
          }
          &:hover {
            background-color: rgba(0, 0, 0, 0.16);
            color: var(--text-primary-for-dark-bg);

            &.pi {
              color: var(--text-primary-for-dark-bg);
            }

            &.disconnect {
              color: var(--error);

              i {
                color: var(--error);
              }
            }
          }
        }
      }
    }
  }
}
