@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

#walletconnect-wrapper {
  .walletconnect-modal__header {
    top: unset;
    img,
    p {
      display: none;
    }
    .walletconnect-modal__close__wrapper {
      top: -12px;
      left: 12px;
    }
  }
  .walletconnect-modal__base {
    border-radius: 0;
    padding: 24px;
    top: unset;
    transform: unset;
    margin: unset;
    overflow: auto;
    height: calc(100% - #{$fixed-bottom-block-height});
    box-shadow: unset;
  }

  .walletconnect-qrcode__base {
    background: transparent;
    padding: 0;
  }
}

.dark-mode .modal.modal-wallet {
  --btn-checked-outline: #007aff;
  --btn-hover: #2c2c2e;
}

.wallet-tab {
  --btn-checked-outline: #1c1c29;
  --btn-hover: rgba(224, 227, 238, 0.5);
  background-color: var(--bg-secondary);

  .connect-wallet {
    .title {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
    }
    .disclaimer {
      margin: 4px 0 24px;
      font-size: 10px;
      line-height: 14px;
      color: var(--text-secondary);
    }

    .wallet-selector {
      display: flex;
      flex-wrap: wrap;
      margin: -16px;

      .connector-button {
        text-align: center;
        margin: 16px;

        .btn-connector {
          width: 100%;
          height: auto;
          padding: 0;
          overflow: visible;
          background: transparent;

          .logo {
            width: 56px;
            height: 56px;
            margin: 0 auto;
            border-radius: 50%;
            background: var(--bg-secondary);
            border: 2px solid var(--bg-secondary);
            transition: all 0.2s ease-out;
            display: block;
            position: relative;

            img {
              height: 28px;
              width: auto;
              margin: 12px auto;
              display: block;
            }

            .checked {
              right: -1px;
              bottom: -1px;
              width: 16px;
              height: 16px;
              color: var(--text-primary-for-dark-bg);
              text-align: center;
              font-size: 6px;
              line-height: 16px;
              background: var(--btn-checked-outline);
              border-radius: 50%;
              display: block;
              position: absolute;
              opacity: 0;
              transition: opacity 0.2s ease-out;
            }
          }

          .title {
            margin: 10px auto 0;
            font-size: 12px;
            font-weight: 500;
            text-align: center;
            display: block;
          }

          &:hover {
            .logo {
              background: var(--btn-hover);
              border: 2px solid var(--btn-hover);
            }
          }

          &.active {
            cursor: pointer;

            .logo {
              border-color: var(--btn-checked-outline);

              .checked {
                opacity: 1;
              }
            }
          }

          &:disabled {
            cursor: not-allowed;
            opacity: 0.5;
          }

          &.btn-metamask {
            .logo {
              img {
                width: 28px;
                height: auto;
                margin: 13px auto;
              }
            }
          }

          &.btn-wallet-connect {
            .logo {
              img {
                width: 28px;
                height: auto;
                margin: 18px auto;
              }
            }
          }
        }
      }
    }
  }

  .connect-wallet-process {
    .view {
      padding: 0 32px 16px;
      text-align: center;

      .icon {
        margin-bottom: 18px;

        &.loading {
          .loading-component {
            height: 50px;
          }
        }

        &.error {
          color: var(--error);
          font-size: 38px;
        }

        &.success {
          color: var(--success);
          font-size: 38px;
        }

        &.warning {
          color: var(--warning);
          font-size: 38px;
        }
      }

      h3 {
        color: var(--text-primary);
        font-size: 23px;
        font-weight: 400;
        margin: 14px 0;
      }

      p {
        margin-bottom: 40px;
        font-size: 14px;
        line-height: 18px;
        color: var(--text-secondary);
        font-weight: normal;

        .address {
          color: var(--text-primary);
        }
      }

      .button-block {
        .btn {
          max-width: 100%;
          width: 200px;
        }
      }
    }
  }
}
