.info-helper {
  top: -3px;
  width: 14px;
  height: 14px;
  margin-left: 5px;
  border-radius: 50%;
  text-align: center;
  font-size: 10px;
  line-height: 14px;
  cursor: help;
  display: inline-block;
  position: relative;

  .ico {
    top: 2px;
    position: relative;
  }

  &.backdrop-gray {
    color: #bec6cb;
    background-color: var(--label-primary);
  }

  &.backdrop-white {
    color: #a4a9bb;
    background-color: var(--label-secondary);
  }
}
