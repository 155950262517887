@font-face {
    font-family: "PlasmaIcons";
    src: url("../fonts/plasma-icons/PlasmaIcons.eot?430c914010da571388f4ba8992486cfc?#iefix") format("embedded-opentype"),
url("../fonts/plasma-icons/PlasmaIcons.woff2?430c914010da571388f4ba8992486cfc") format("woff2"),
url("../fonts/plasma-icons/PlasmaIcons.woff?430c914010da571388f4ba8992486cfc") format("woff"),
url("../fonts/plasma-icons/PlasmaIcons.ttf?430c914010da571388f4ba8992486cfc") format("truetype"),
url("../fonts/plasma-icons/PlasmaIcons.svg?430c914010da571388f4ba8992486cfc#PlasmaIcons") format("svg");
}

.pi {
  display: inline-block;
  font: 400 14px/1 "PlasmaIcons", sans-serif;
  font-size: inherit;
  text-rendering: auto;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-all:before {
    content: "\f101";
}

.pi-back:before {
    content: "\f102";
}

.pi-change:before {
    content: "\f103";
}

.pi-check:before {
    content: "\f104";
}

.pi-close:before {
    content: "\f105";
}

.pi-copy:before {
    content: "\f106";
}

.pi-disconnect:before {
    content: "\f107";
}

.pi-disconnect-wallet:before {
    content: "\f108";
}

.pi-down:before {
    content: "\f109";
}

.pi-error:before {
    content: "\f10a";
}

.pi-info:before {
    content: "\f10b";
}

.pi-more:before {
    content: "\f10c";
}

.pi-open-new-alt:before {
    content: "\f10d";
}

.pi-plus:before {
    content: "\f10e";
}

.pi-portfolio-menu:before {
    content: "\f10f";
}

.pi-refresh:before {
    content: "\f110";
}

.pi-repeat:before {
    content: "\f111";
}

.pi-search:before {
    content: "\f112";
}

.pi-settings:before {
    content: "\f113";
}

.pi-success:before {
    content: "\f114";
}

.pi-swap:before {
    content: "\f115";
}

.pi-warning:before {
    content: "\f116";
}

.pi-rotate {
  animation: 2s pi-rotate linear infinite;
}

@keyframes pi-rotate {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes pi-rotate {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
