@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.modal-tx-error-view {
  text-align: center;

  .error-animation {
    width: 100%;
    height: auto;
  }

  .icon {
    max-width: 100%;
    margin: 0 auto;
    height: 150px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .caption {
    font-size: 26px;
    color: $error;
  }

  .info {
    margin-top: 16px;
    padding: 0 16px;
    font-size: 16px;
    color: var(--text-secondary);
  }

  .link {
    margin-top: 10px;
  }

  .button-block {
    margin-top: 32px;

    .btn {
      width: 100%;
    }
  }
}
