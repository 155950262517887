.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-thirdly);
  text-align: center;
  a {
    color: var(--text-thirdly);
  }
  .hyper-dex-link {
    white-space: nowrap;
  }
  .plasma-link {
    white-space: nowrap;
  }
}
