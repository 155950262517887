$widget-width: 430px;
$fixed-bottom-modal-height: 45px;
$fixed-bottom-block-height: 30px;
$window-padding: 24px;
// Colors
// $primary: #191E2E;
$success: #1fc781;
$error: #fe453d;
// $attention: #FF5B88;

// $bg-primary: #F5F5F9;
// $bg-secondary: #DBDDE1;

// $btn-thirdly: #E3E5EB;

// // Sizes
// $main-menu-width: 230px;
// $closed-main-menu-width: 72px;
// $header-height: 52px;
// $mobile-header-height: 40px;
// $max-container-width: 1120px;

// Z - placement
$z-index-tooltip: 9999;
$z-index-settings-popup: 100;

// // Other
$modal-border-radius: 16px;
