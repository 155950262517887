.network-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  height: 20px;
  & + .network-item {
    margin-top: 16px;
  }
  .network {
    width: 30%;
    display: flex;
    align-items: center;
    .network-name {
      margin-left: 9px;
      white-space: nowrap;
    }
    .network-logo {
      .tooltiped-element {
        display: block;
        img {
          display: block;
        }
      }
    }
  }
  .share {
    width: 35%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .formatted-percent {
      display: inline-flex;
      align-items: center;
      height: 100%;
      padding: 0 6px;
      background-color: var(--bg-thirdly);
      border-radius: 4px;
    }
  }
  .value {
    width: 35%;
    text-align: right;
  }
}
