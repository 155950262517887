@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.tooltiped-element {
  display: inline-block;
}

.tooltip {
  max-width: 280px;
  padding: 10px;
  color: var(--tooltip-text);
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  background-color: var(--tooltip-bg);
  border-radius: 4px;
  opacity: 0;
  transition: opacity .2s ease-out;
  display: inline-block;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: $z-index-tooltip;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    z-index: 1;

    &.tooltip-arrow-top {
      bottom: -10px;
      border-top-color: var(--tooltip-bg);
    }

    &.tooltip-arrow-bottom {
      top: -10px;
      border-bottom-color: var(--tooltip-bg);
    }

    &.tooltip-arrow-left {
      right: -10px;
      border-left-color: var(--tooltip-bg);
    }

    &.tooltip-arrow-right {
      left: -10px;
      border-right-color: var(--tooltip-bg);
    }
  }
}
