@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

[data-reach-dialog-overlay] {
  display: flex;
  justify-content: center;
  z-index: 100;
  background-color: transparent;
  position: absolute;
  right: unset;
  width: 100%;
}

[data-reach-dialog-content] {
  background: var(--bg-secondary);
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
