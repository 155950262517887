@import 'variables';

.btn {
  height: 40px;
  padding: 3px 14px;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  overflow: hidden;
  text-overflow: ellipsis;

  &.btn-primary {
    background-color: var(--btn-primary);
    color: var(--text-primary-for-dark-bg);

    &:hover {
      background-color: var(--btn-primary-hover);
    }

    &:disabled,
    &.disabled {
      background-color: var(--btn-primary-disabled);
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  &.btn-secondary {
    color: var(--text-primary);
    background-color: var(--btn-secondary);

    &:hover {
      background-color: var(--btn-secondary-hover);
    }

    &:disabled,
    &.disabled {
      opacity: 0.7;
      background-color: var(--btn-secondary-disabled);
      cursor: not-allowed;
    }
  }

  &.btn-thirdly {
    color: var(--text-primary);
    background-color: var(--btn-thirdly);

    &:hover {
      background-color: var(--btn-thirdly-hover);
    }

    &:disabled,
    &.disabled {
      opacity: 0.7;
      background-color: var(--btn-thirdly-disabled);
      cursor: not-allowed;
    }
  }

  // &.btn-danger {
  //   color: white;
  //   background-color: var(--btn-danger);
  //   transition: background-color .2s ease-out;

  //   &:hover {
  //     background-color: var(--btn-danger-hover);
  //   }

  //   &:disabled,
  //   &.disabled {
  //     background-color: var(--btn-danger-disabled);
  //     opacity: .7;
  //     cursor: not-allowed;
  //   }
  // }

  // &.btn-success {
  //   color: var(--btn-success-color);
  //   background-color: var(--btn-success);
  //   transition: background-color .2s ease-out;

  //   &:hover {
  //     background-color: var(--btn-success-hover);
  //   }

  //   &:disabled,
  //   &.disabled {
  //     background-color: var(--btn-success-disabled);
  //     color: var(--text-secondary);
  //     opacity: .7;
  //     cursor: not-allowed;
  //   }
  // }

  // &.btn-gradient {
  //   color: white;
  //   background: linear-gradient(280.7deg, #FF24CF 0%, #FF6F6F 100%);

  //   &:hover {
  //     opacity: .8;
  //   }
  // }

  &.btn-close {
    height: 14px;
    width: 14px;
    padding: 0;
    font-size: 12px;
    color: var(--text-thirdly);
    background: transparent;
    transition: color 0.2s ease-out;

    &:hover {
      color: var(--text-secondary);
    }
  }

  // &.btn-hamburger {
  //   height: 46px;
  //   width: 46px;
  //   padding: 10px;
  //   display: inline-block;
  //   cursor: pointer;
  //   transition-property: opacity, filter;
  //   transition-duration: .15s;
  //   transition-timing-function: linear;
  //   font: inherit;
  //   color: inherit;
  //   text-transform: none;
  //   background-color: transparent;
  //   border: 0;
  //   margin: 0;
  //   overflow: visible;
  //   outline: none;

  //   .hamburger-box {
  //     width: 27px;
  //     height: 21px;
  //     display: block;
  //     position: relative;

  //     .hamburger-inner {
  //       display: block;
  //       top: 50%;
  //       margin-top: -1.5px;
  //       transition-duration: .22s;
  //       transition-timing-function: cubic-bezier(.55, .055, .675, .19);

  //       &:before,
  //       &:after {
  //         content: "";
  //         display: block;
  //       }

  //       &,
  //       &:before,
  //       &:after {
  //         width: 27px;
  //         height: 3px;
  //         background-color: var(--btn-hamburger);
  //         border-radius: 2px;
  //         position: absolute;
  //       }

  //       &:before {
  //         top: -9px;
  //         transition: top .1s .25s ease-in, opacity .1s ease-in;
  //       }

  //       &:after {
  //         bottom: -9px;
  //         transition: bottom .1s .25s ease-in, transform .22s cubic-bezier(.55, .055, .675, .19);
  //       }
  //     }
  //   }

  //   &.is-active {

  //     .hamburger-box {

  //       .hamburger-inner {
  //         transform: rotate(225deg);
  //         transition-delay: .12s;
  //         transition-timing-function: cubic-bezier(.215, .61, .355, 1);

  //         &::before {
  //           top: 0;
  //           opacity: 0;
  //           transition: top .1s ease-out, opacity .1s .12s ease-out;
  //         }

  //         &::after {
  //           bottom: 0;
  //           transform: rotate(-90deg);
  //           transition: bottom .1s ease-out, transform .22s .12s cubic-bezier(.215, .61, .355, 1);
  //         }
  //       }
  //     }
  //   }
  // }

  // &.btn-linky {
  //   height: unset;
  //   color: var(--text-secondary);
  //   padding: 0;
  //   background-color: transparent;

  //   &:hover {
  //     text-decoration: underline;
  //   }

  //   &.bright {
  //     color: var(--text-link);
  //   }
  // }

  &.btn-icon {
    width: 26px;
    height: 26px;
    padding: 2px;
    font-size: 22px;
    color: var(--text-secondary);
    border-radius: 50%;
    background-color: transparent;
    transition: opacity 0.2s ease-out;
    position: relative;

    .pi {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: ' ';
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all 0.25s ease;
      border-radius: 50%;
      box-shadow: inset 0 0 0 0 var(--btn-icon-hover);
      position: absolute;
    }

    &:hover {
      &:before {
        box-shadow: inset 0 0 0 26px var(--btn-icon-hover);
      }
    }
  }

  &.btn-icon-rect {
    width: 32px;
    height: 32px;
    padding: 0;
    font-size: 16px;
    text-align: center;
    line-height: 38px;
    color: var(--text-primary);
    border-radius: 4px;
    background-color: var(--btn-icon-rect);
    transition: all 0.2s ease-out;

    &.btn-foreground-white {
      background-color: var(--btn-icon-rect-background-light);
    }

    &:hover {
      background-color: var(--btn-icon-rect-hover);
      color: var(--text-primary-for-dark-bg);
    }

    &.active {
      background-color: var(--btn-icon-rect-active);
      color: var(--text-primary-for-dark-bg);
    }

    &:disabled,
    &.disabled {
      color: var(--text-secondary);
      background-color: var(--btn-icon-rect);
      cursor: default;
      opacity: 0.7;
    }
  }

  // &.btn-dropdown {
  //   height: auto;
  //   padding: 2px 0;
  //   background-color: transparent;

  //   .arrow {
  //     left: 0;
  //     bottom: -4px;
  //     width: 6px;
  //     height: 13px;
  //     margin-left: 14px;
  //     text-align: left;
  //     display: inline-block;
  //     position: relative;

  //     &:before,
  //     &:after {
  //       content: "";
  //       top: 0;
  //       left: 0;
  //       width: 2px;
  //       height: 6px;
  //       background-color: transparent;
  //       display: inline-block;
  //       position: absolute;
  //       border-bottom: 8px solid var(--text-primary);
  //       border-radius: 1px;
  //       transition: background-color .2s ease-out;
  //     }

  //     &:before {
  //       transform: rotate(-135deg);
  //     }

  //     &:after {
  //       transform: rotate(135deg);
  //     }
  //   }

  //   &.active {

  //     .arrow {
  //       transform: translate(0, -5px);

  //       &:before {
  //         transform: rotate(-45deg);
  //       }

  //       &:after {
  //         transform: rotate(45deg);
  //       }
  //     }
  //   }
  // }

  &.btn-max {
    height: 24px;
    padding: 0 7px;
    font-size: 14px;
    color: var(--text-primary);
    border-radius: 4px;
    background-color: var(--btn-max);
    transition: all 0.2s ease-out;

    &:hover {
      color: var(--text-primary-for-dark-bg);
      background-color: var(--btn-max-hover);
    }
  }

  // &.btn-xs {
  //   height: 28px;
  //   font-size: 14px;
  // }

  // &.btn-sm {
  //   height: 32px;
  //   font-size: 14px;
  //   line-height: 16px;
  // }

  &.btn-lg {
    height: 56px;
    border-radius: 8px;
  }
}
