@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.modal-hyper-dex-swap-confirm {
  .modal-content {
    .gsn-fee-tokens-selector-wrap {
      margin-top: 10px;
    }

    .label-container {
      margin-top: 24px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .label {
        color: var(--text-secondary);
        font-weight: 500;
        font-size: 14px;
      }

      .value {
        color: var(--text-primary);

        .provider {
          display: flex;
          align-items: center;

          .info {
            margin-right: 6px;
            color: var(--text-secondary);
            font-weight: normal;
          }

          .name {
            margin-left: 6px;
          }
        }

        .fiat {
          margin-right: 6px;
          color: var(--text-secondary);
          font-weight: normal;
        }
      }

      &.secondary {
        .label {
          font-weight: 400;
        }

        .value {
          font-weight: 700;
        }
      }
    }

    .swap-tokens-block {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .swap-token {
        width: 100%;
        margin-top: 2px;
        padding: 12px 16px;
        border-radius: 8px;
        background: var(--bg-primary);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .info {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .symbol {
            margin-left: 12px;
            font-weight: 700;
          }
        }

        .amount {
          text-align: right;

          .crypto {
            margin-bottom: 6px;
            font-weight: 700;
          }

          .fiat {
            color: var(--text-secondary);
          }
        }
      }

      .separator {
        top: 14px;
        width: 28px;
        height: 28px;
        margin-top: -28px;
        text-align: center;
        font-size: 12px;
        line-height: 29px;
        border-radius: 8px;
        border: 2px solid var(--bg-secondary);
        background: var(--bg-primary);
        position: relative;
      }
    }

    .trade-info {
      .label-container {
        margin: 10px 0;
      }
    }

    .button-block {
      margin-top: 16px;

      .btn {
        width: 100%;
      }
    }
  }
}
