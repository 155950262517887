.asset-item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  & + .asset-item {
    margin-top: 16px;
  }
  .symbol-cell {
    height: 24px;
    width: 30%;
    display: flex;
    align-items: center;

    .logo-block {
      position: relative;
      height: 24px;

      .second-logo {
        margin-left: -13px;
      }

      .sub-logo {
        position: absolute;
        top: -6px;
        right: -6px;

        img {
          border: 2px solid var(--bg-secondary);
          border-radius: 50%;
        }
      }
    }

    .symbol {
      margin-left: 10px;
      word-break: break-all;

      a {
        text-decoration: none;
        color: var(--text-primary);
      }
    }
  }

  .balance {
    text-align: right;
    width: 35%;
  }
  .value {
    width: 35%;
    text-align: right;
  }
}
