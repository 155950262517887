@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.form-control-input {
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px;
    font-size: 14px;
    color: var(--text-secondary);
  }

  .input-wrap {
    padding: 14px 16px;
    background-color: var(--form-control-background);
    border: 1px solid var(--form-control-border);
    border-radius: 8px;
    transition: border-color .2s ease-out;
    position: relative;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      max-width: 100%;
      outline: none;
      border: none;
      background-color: transparent;
    }

    .suffix {
      flex-shrink: 0;
    }

    &:focus-within {
      border-color: var(--form-control-outline);
    }
  }

  .error-massage {
    margin: 3px 0 0 8px;
    font-size: 10px;
    color: $error;
  }

  &.is-error {

    .input-wrap {
      border-color: var(--error);
    }
  }

  &.is-disabled {

    .input-wrap {
      background-color: var(--form-control-disabled-background);
      border-color: var(--form-control-disabled-border);
    }
  }
}
