@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.formatted-percent {

  &.show-sign {

    &.up {

      .percent:before {
        content: "+";
      }
    }

    &.down {

      .percent:before {
        content: "-";
      }
    }
  }

  &.color {

    &.up {
      color: $success;
    }

    &.down {
      color: $error;
    }
  }
}
