html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  #root {
    height: 100%;
    overflow: hidden;
  }
  // Placeholder
  ::-moz-placeholder {
    color: var(--form-control-pleaceholder);
  }

  ::-webkit-input-placeholder {
    color: var(--form-control-pleaceholder);
  }

  :-ms-input-placeholder {
    color: var(--form-control-pleaceholder);
  }

  ::-ms-input-placeholder {
    color: var(--form-control-pleaceholder);
  }

  ::placeholder {
    color: var(--form-control-pleaceholder);
  }

  // Scrollbar
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    padding: 4px 0;
    background-color: transparent;
  }

  ::-webkit-scrollbar-button {
    height: 6px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scroll-bar);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

* {
  box-sizing: border-box;
}

.drop-down {
  display: block;
  width: 11px;
  min-width: 11px;
  max-width: 11px;
  height: 5px;
  position: relative;

  &:before,
  &:after {
    content: '';
    top: 2px;
    width: 6.8px;
    height: 1px;
    background-color: var(--text-primary);
    position: absolute;
  }

  &:before {
    left: 0;
    transform: rotate(45deg);
  }

  &:after {
    right: 0;
    transform: rotate(-45deg);
  }

  &.active {
    &:before {
      transform: rotate(-45deg);
    }

    &:after {
      transform: rotate(45deg);
    }
  }
}

.modal {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .modal-header {
    padding: 0 $window-padding;
    margin-bottom: 12px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      height: auto;
      padding: 4px;
      font-size: 12px;
      color: var(--text-thirdly);
      transition: color 0.2s ease-out;
      background: transparent;

      &:hover {
        color: var(--text-secondary);
      }

      &:disabled {
        visibility: hidden;
      }
    }

    .title {
      font-size: 20px;
      font-weight: 700;
    }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    padding: 0 24px;
  }
  .modal-footer {
    padding: 0 $window-padding;
  }
}

.row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
