@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.modal-tx-sent-view {
  text-align: center;

  .icon {
    max-width: 100%;
    height: 136px;
    margin-top: -12px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 30px;

    .animation-success {
      width: 100%;
      height: calc(100% + 15px);
      margin: -15px 0;
    }

    .animation-waiting {
      width: 90px;
    }
  }

  .caption {
    font-size: 26px;
  }

  .confirmations {
    margin-top: 4px;
    color: var(--text-thirdly);
    font-size: 12px;
  }

  .subtext {
    color: var(--text-secondary);
    margin-top: 12px;
  }

  .button-block {
    margin-top: 22px;

    .btn {
      width: 100%;
      margin-top: 10px;
    }
  }
}
