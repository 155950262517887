@import "../../assets/scss/variables";

.dark-mode .modal-bridge-evm-confirm {
  --note-text-bg: #515153;
}

.modal-bridge-evm-confirm {
  --note-text-bg: #f5f5f9;

  width: 432px;

  .modal-header {
    position: relative;
    z-index: 5;
  }

  .modal-content {
    padding-top: 10px;

    .caption {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 24px;
    }

    .swap-info {
      position: relative;

      .label {
        font-size: 14px;
        color: var(--text-secondary);
      }

      .token-row {
        margin: 16px 0;
        font-size: 24px;
        display: flex;
        align-items: center;

        .logo {
          margin-right: 12px;
        }

        .amount {
          margin-right: auto;
          font-weight: 700;
        }

        .symbol {
          font-weight: 500;
        }
      }

      .separator {
        padding-left: 6px;
        font-size: 18px;
      }
    }

    .bridge-transfer {

      .label {
        font-size: 14px;
        color: var(--text-secondary);
      }

      .chains {
        display: flex;
        position: relative;
        margin-top: 8px;

        & > div {
          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 6px;
          }
        }

        .separator {
          position: relative;
          width: 100%;
          height: 0;
          margin-top: 12px;
          border: 1px dashed #b2b4bb;
          margin-left: 16px;
          margin-right: 16px;

          i {
            position: absolute;
            right: 0;
            color: #b2b4bb;
          }
        }
      }
    }

    .bridge-info {
      margin-top: 26px;
      text-align: center;

      .label {
        margin-bottom: 8px;
        font-size: 14px;
        color: var(--text-secondary);
      }

      .amount {
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
      }
    }

    .disclaimer {
      padding: 12px;
      background: linear-gradient(280.7deg,rgba(255,78,216,.1),hsla(7,100%,79%,.1));
      border-radius: 8px;
    }

    .awaiting-animation {
      width: 200px;
      margin: -20px auto 24px;
      position: relative;

      .chain-logo {
        top: 76px;
        left: calc(50% - 24px);
        width: 48px;
        height: 48px;
        position: absolute;
      }
    }

    .transaction-confirmation {
      display: flex;
      flex-direction: column;
      align-items: center;

      .caption {
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
      }

      .swap-info {
        margin-bottom: 16px;
        font-size: 16px;
        line-height: 19px;
      }

      .note {
        margin-bottom: 32px;
        padding: 8px 12px;
        background: var(--note-text-bg);
        text-align: center;
        border-radius: 4px;
      }
    }

    .separator {
      height: 20px;
    }

    .progress-bar {
      margin-bottom: 20px;
      position: relative;

      .label {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
      }

      .progress-bar-wrapper {
        height: 8px;
        margin-bottom: 10px;
        background: #ededf2;
        border-radius: 8px;

        .progress-bar-value {
          background: linear-gradient(90deg, #191e2e 0%, #888ea0 71.88%, #191e2e 85.42%, #191e2e 100%);
          height: 8px;
          border-radius: 8px;
          transition: width 1s ease-in-out;
        }
      }

      .info {
        text-align: center;
        color: var(--text-secondary);
        font-size: 12px;
      }
    }

    .tx-links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 24px;

      a {
        display: flex;
        align-items: center;
        margin: 0 8px;
        color: var(--text-secondary);
        text-decoration: none;

        &:hover {
          color: var(--text-primary);
        }

        .pi-back {
          font-size: 8px;
          transform: rotate(135deg);
          display: block;
          background: var(--bg-primary);
          border-radius: 50%;
          height: 16px;
          width: 16px;
          text-align: center;
          margin-left: 4px;
          line-height: 16px;
        }
      }
    }
  }

  .modal-footer {
    background: var(--bg-thirdly);
    padding: 16px 24px 24px;
    margin-bottom: -24px;
    border-radius: 0 0 16px 16px;

    .estimation-block {
      margin-bottom: 16px;

      .row-between {
        height: 32px;
        padding: 3px 0;
        color: var(--text-secondary);
        font-size: 14px;
        border-bottom: 1px solid var(--row-separator);

        .value {
          color: var(--text-primary);
          font-weight: 500;

          .logo {
            top: 2px;
            margin-right: 6px;
            position: relative;
            display: inline-block;
          }
        }
      }
    }

    .btn {
      width: 100%;
    }
  }
}
