@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.modal-loading-view {
  text-align: center;

  .loading {
    width: 300px;
    max-width: 100%;
    margin: 46px auto 0;
  }

  .caption {
    margin-top: 50px;
    font-size: 26px;
  }

  .sub-caption {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: $error;
  }

  .note-text {
    margin-top: 25px;
    margin-bottom: 16px;
    padding: 6px 10px;
    border-radius: 4px;
    background-color: var(--bg-primary);
    display: inline-block;
  }
}
