@import "src/assets/scss/variables";

.currency-logo-wrapper {
  display: inline-flex;
  align-items: flex-start;

  .currency-logo-background {
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--bg-primary);
    display: block;

    img {
      display: block;
    }
  }

  :nth-child(2) {
    margin-left: -18%;
  }

  .provider-container {
    margin-left: -14%;
    margin-top: -9%;

    img {
      display: block;
    }
  }
}
