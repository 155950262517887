@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.swap-form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: var(--bg-secondary);
  border-radius: 0 0 16px 16px;
  position: relative;
  z-index: 2;

  .form-field {
    .field-label {
      margin-bottom: 8px;
      color: var(--text-secondary);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .save-info {
        padding: 4px 6px 2px 8px;
        font-size: 12px;
        font-weight: 500;
        color: var(--text-primary-for-dark-bg);
        background: #2d976b;
        border-radius: 4px;
        cursor: default;

        .info-helper {
          top: -2px;
          background-color: var(--text-primary-for-dark-bg);
        }
      }
    }
  }

  .swap-fields-button {
    margin: 12px 0;
    text-align: center;
  }

  .trade-info {
    margin-top: 16px;

    .row {
      height: 16px;
      font-size: 12px;
      color: var(--text-secondary);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .value {
        position: relative;
        display: flex;
        align-items: center;

        .btn {
          top: 1px;
          height: unset;
          width: unset;
          margin-left: 7px;
          padding: 4px;
          font-size: 12px;
          border-radius: 4px;
          position: relative;

          .pi {
            display: block;
          }

          &:before {
            border-radius: 4px;
          }
        }
      }

      .label {
        .sub-label {
          margin-top: 2px;
          font-size: 10px;
          color: var(--text-thirdly);
        }
      }
    }
  }

  .button-block {
    margin-top: 16px;

    .btn {
      width: 100%;
    }

    .should-approve {
      display: flex;
      justify-content: space-between;

      .btn {
        width: 48%;
      }
    }
  }
}
