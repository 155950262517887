@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.dark-mode .btn-refresh {
  --arrow-bg: rgba(236, 236, 245, 0.2);
  --arrow-progress: #ececf5;
  --arrow-bg-hover: rgba(236, 236, 245, 0.2);
  --arrow-progress-hover: #ececf5;
}

.btn.btn-refresh {
  height: 16px;
  width: 16px;
  padding: 0;
  --arrow-bg: rgba(0, 0, 0, 0.2);
  --arrow-progress: black;
  --arrow-bg-hover: rgba(255, 255, 255, 0.4);
  --arrow-progress-hover: white;
  background: var(--bg-primary);

  svg {
    width: 100%;
    height: 100%;

    .arrow-bg {
      fill: var(--arrow-bg);
    }

    .arrow-progress {
      stroke: var(--arrow-progress);
    }
  }

  &:disabled {
    &:hover {
      cursor: default;
    }
  }
}
