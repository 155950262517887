@import "src/assets/scss/variables";
@import "src/assets/scss/mixins";

.loading-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .loader {

    svg {
      height: 100% !important;
      width: auto !important;
    }
  }

  &.size-lg {
    height: 100px;

    .loader {
      height: 140px;
    }
  }

  &.size-md {
    height: 70px;

    .loader {
      height: 100px;
    }
  }

  &.size-sm {
    height: 26px;

    .loader {
      height: 34px;
    }
  }

  &.size-xs {
    height: 16px;
    width: 58px;

    .loader {
      height: 18px;
    }
  }

  &.size-auto {
    height: 100%;

    .loader {
      height: calc(100% + 16px);
    }
  }
}
