@import 'src/assets/scss/variables';

.hyper-dex-swap-widget {
  background: var(--bg-secondary);
  max-width: $widget-width;
  position: relative;
  padding: $window-padding $window-padding 0 $window-padding;
  height: 100%;
  display: flex;
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
  }
  .widget-tabs {
    margin: 8px 0 12px;
    display: flex;
    width: 100%;
    background-color: var(--bg-fourthly);
    min-height: 32px;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    border-radius: 6px;
    .tab-item {
      width: 50%;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      cursor: pointer;
      &.active {
        cursor: default;
        background-color: var(--bg-secondary);
      }
    }
  }
  .scroll-content {
    overflow: scroll;
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 -#{$window-padding};
    padding: 0 #{$window-padding - 4px} 0 $window-padding;
    width: calc(100% + #{$window-padding * 2});
    &.modal-mode {
      padding-bottom: $fixed-bottom-modal-height;
    }
    &.block-mode {
      padding-bottom: $fixed-bottom-block-height;
    }
  }

  .fixed-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
    background-color: var(--bg-secondary);
    &.modal-mode {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: $fixed-bottom-modal-height;
    }
    &.block-mode {
      height: $fixed-bottom-block-height;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-area {
      height: $fixed-bottom-block-height;
      font-size: 12px;
      color: var(--text-thirdly);
      display: flex;
      align-items: center;
      justify-content: center;

      .close-area-icon {
        margin-left: 8px;
        font-size: 9px;
      }
    }
  }
}
