@import 'src/assets/scss/variables';
@import 'src/assets/scss/mixins';

.dark-mode .advanced-pool-settings {
  --btn-radio-bg: #242426;
  --btn-radio-active: #3a3a3c;
  --btn-radio-hover: #515153;
  --search-border-color: transparent;
}

.advanced-pool-settings {
  --btn-radio-bg: #f5f5f9;
  --btn-radio-active: #191e2e;
  --btn-radio-hover: #353a4a;
  --search-border-color: #eaecf2;

  position: relative;

  .settings-popup {
    top: 34px;
    right: 0;
    width: 330px;
    padding: 24px 24px 16px;
    background-color: var(--bg-dropdown);
    box-shadow: var(--shadow-dropdown);
    border-radius: 8px;
    border: 1px solid var(--border-primary);
    display: grid;
    grid-auto-rows: auto;
    row-gap: 12px;
    position: absolute;
    z-index: $z-index-settings-popup;

    .block-label {
      margin: 0 0 10px;
    }

    .transaction-settings {
      .settings-block {
        margin-bottom: 16px;

        .label {
          margin-bottom: 10px;
          font-weight: 400;
          font-size: 14px;
          color: var(--text-secondary);
          display: flex;
          align-items: center;

          .btn {
            margin-left: auto;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .settings-buttons {
          display: grid;
          column-gap: 6px;
          grid-auto-flow: column;
          position: relative;
          z-index: 2;

          .btn-fixed-value {
            height: 32px;
            width: 56px;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: var(--text-primary);
            background-color: var(--btn-radio-bg);
            border-radius: 6px;
            transition: all 0.2s ease-out;

            &.active {
              background-color: var(--btn-radio-active);
              color: var(--text-primary-for-dark-bg);
            }

            &:hover {
              background-color: var(--btn-radio-hover);
              color: var(--text-primary-for-dark-bg);
            }
          }

          .custom-value {
            height: 32px;
            width: 86px;
            padding: 0 8px;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            border: 1px solid var(--form-control-border);
            transition: all 0.2s ease-out;
            border-radius: 6px;
            display: flex;
            align-items: center;

            .warning-icon {
              color: var(--error);
            }

            input {
              height: 100%;
              width: 100%;
              margin-left: auto;
              padding: 0 5px;
              color: var(--text-primary);
              outline: none;
              text-align: right;
              border: none;
              background-color: transparent;
            }

            &:focus-within {
              border-color: var(--form-control-outline);
            }

            &.active {
              background-color: var(--form-control-background);
            }

            &.invalid {
              border-color: var(--error);
            }
          }
        }

        .settings-errors {
          min-height: 16px;
          margin-top: 5px;
          font-size: 10px;

          .warning {
            color: var(--warning);
          }

          .error {
            color: var(--error);
          }
        }

        .liquidity-providers {
          margin: 0 -3px;
          display: flex;
          flex-wrap: wrap;

          .liquidity-provider-item {
            margin: 3px;
            position: relative;

            .radio-button {
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              opacity: 0;
              cursor: pointer;
              position: absolute;
            }

            .info-block {
              padding: 4px;
              background-color: var(--btn-radio-bg);
              display: flex;
              align-items: center;
              transition: background-color 0.2s ease;
              border-radius: 6px;

              .logo {
                > * {
                  display: block;
                }
              }

              .name {
                padding: 0 8px;
                color: var(--text-primary);
                font-size: 14px;
                font-weight: 500;
                transition: color 0.2s ease;
              }
            }

            .radio-button:checked + .info-block {
              background-color: var(--btn-radio-active);

              .name {
                color: var(--text-primary-for-dark-bg);
              }
            }

            .radio-button:hover + .info-block {
              background-color: var(--btn-radio-hover);

              .name {
                color: var(--text-primary-for-dark-bg);
              }
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .liquidity-sources {
      margin-bottom: 10px;

      .label {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        color: var(--text-secondary);
        display: flex;
        align-items: center;
      }

      .enabled-sources-count {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .count {
          font-weight: 500;
          font-size: 16px;
        }

        .btn {
          min-width: 102px;
          height: 32px;
          font-size: 14px;
        }
      }
    }
  }

  .sources-popup {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 24px 24px 16px;
    background-color: var(--bg-dropdown);
    border: 1px solid var(--border-primary);
    display: grid;
    grid-auto-rows: auto;
    row-gap: 12px;
    position: fixed;
    z-index: $z-index-settings-popup;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .caption {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .search {
      height: 40px;
      margin: 0 -24px;
      padding: 8px 24px;
      border: 0.75px solid var(--search-border-color);
      border-left: none;
      border-right: none;
      transition: opacity 0.3s ease-out;
      background-color: transparent;
      display: flex;
      align-items: center;

      .icon {
        color: var(--text-secondary);
      }

      .search-input {
        width: 85%;
        padding-left: 16px;
        border: none;
        background: transparent;
        outline: none;
      }
    }

    .liquidity-sources {
      max-height: 380px;
      margin: 0 -24px;
      overflow: auto;

      .source-item {
        padding: 6px 20px 6px 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon {
          margin-right: 12px;

          img {
            width: 24px;
            height: 24px;
            display: block;
          }
        }

        .name {
          margin-right: auto;
          font-weight: 500;
        }
      }
    }
  }

  @include xs {
    .settings-popup {
      // right: -30px;
      width: 92vw;
      max-width: 330px;
      padding: 22px;
    }
  }
}
