.dark-mode .toggle-switch {
  --toggle-switch-bg: #1c1c1e;
  --toggle-switch-thumb: #3a3a3c;
  --toggle-switch-border: #1c1c1e;
}

.toggle-switch {
  --toggle-switch-bg: white;
  --toggle-switch-thumb: #191e2e;
  --toggle-switch-border: #e9e9e9;

  height: 30px;
  width: 56px;
  background-color: var(--toggle-switch-bg);
  border: 1px solid var(--toggle-switch-border);
  border-radius: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .hidden-checkbox {
    left: -100px;
    position: absolute;
  }

  .switch {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: block;
    position: absolute;

    .switch-thumb {
      top: 3px;
      left: 3px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: var(--toggle-switch-thumb);
      transition: left 0.2s ease-in-out;
      display: block;
      position: absolute;
      z-index: 2;
    }

    &:after {
      content: 'Off';
      right: 8px;
      top: 7px;
      font-size: 12px;
      font-weight: 500;
      color: var(--text-primary);
      position: absolute;
    }
  }

  .hidden-checkbox:checked + .switch {
    .switch-thumb {
      left: 28px;
    }

    &:after {
      content: 'On';
      right: unset;
      left: 8px;
    }
  }

  .hidden-checkbox:disabled + .switch {
    opacity: 0.5;
    cursor: default;
  }
}
